import angular, { IHttpResponse, ILogService } from "angular";
import { app } from "../app.module";
import { IDeviceService } from "./deviceServiceProvider";
import { getHttpHeaders } from "../config.http";
import { CapacitorHttp, HttpOptions, HttpResponse, HttpResponseType } from "@capacitor/core";
import { IUrlResolver } from "./urlResolver";
import { v5 as uuidv5 } from "uuid";
import CryptoJs from "crypto-js";
import { LoginRequest } from "../models/api";
import { IErrorLoggingService } from "./errorLoggingService";

const serviceId = "apiService";
export interface IApiService {
    getStudentMedicalConditionTypes<T = any>(): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;

    getIsMaintenance<T = any>(): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    getAuthenticationCheck<T = any>(): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    getCrsfToken<T = any>(): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    getDownloadToken<T = any>(): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    getRegistration<T = any>(sessionId: string): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    getDelegatedViewerInvitation<T = any>(code: string): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    getUser<T = any>(): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    getUserEmail<T = any>(qId: string): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    getAdminViewUser<T = any>(schoolUserId: string, ppaoId: string, centreCode: string): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;

    getOutageById<T = any>(id: string): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    getOutage<T = any>(platform: string, module: string): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    getAlert<T = any>(platform: string, module: string): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    getMinimumApiVersion<T = any>(): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    getQLearnLink<T = any>(): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    getTermsAndConditions<T = any>(toc: string): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;

    getConsentRequest<T = any>(studentEqId: string, requestId: string, schoolCode: string): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    getConsentRequestSummary<T = any>(query: string): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;

    downloadAttachment<T = any>(url: string): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    downloadPhoto<T = any>(url: string): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;

    login<T = any>(data: LoginRequest): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    logout<T = any>(): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    changePassword<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    changeEmail<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    changeMobile<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    reportAbsence<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    submitAbsenceReason<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    reportMultipleAbsences<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    forgotPasswordRequest<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    forgotPasswordSubmit<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    adminViewLogout<T = any>(): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    recordAudit<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    recordMobileAppNeedsUpgrade<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    acceptTermsAndConditions<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    acceptPendingTermsAndConditions<T = any>(): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    accountOwnerLogin<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    accountOwnerRegister<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    accountOwnerActivate<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    accountOwnerInvitation<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    delegatedViewerLogin<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    delegatedViewerRegister<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    delegatedViewerInvite<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    delegatedViewerUpdate<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    delegatedViewerResendInvite<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    delegatedViewerChangeStatus<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    associateAdditionalInvitation<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;

    sendConsentResponse<T = any>(studentEqId: string, requestId: string, schoolCode: string, data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;

    saveUser<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    saveStudentRelation<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    saveStudent<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    saveStudentMedicalConditions<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    removeStudentPhoto<T = any>(oneSchoolId: string): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    studentUseSchoolPhoto<T = any>(studentId: string): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    promoteStudentPhoto<T = any>(studentId: string, type: string): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    uploadStudentPhoto<T = any>(studentId: string, data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    emailAcademicReportArchive<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;

    paymentAuthenticationToken<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    verifyPaymentResponse<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    auditPayment<T = any>(data: any): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;

    registerPushNotification<T = any>(handle: string): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
    unregisterPushNotification<T = any>(handle: string): angular.IPromise<HttpResponse | angular.IHttpResponse<T>>;
}

class appServiceProvider implements angular.IServiceProvider {
    public $get = [
        "$http",
        "$rootScope",
        "$q",
        "$injector",
        "$log",
        "deviceService",
        "errorLoggingService",
        (
            $http: angular.IHttpService,
            $rootScope: any,
            $q: angular.IQService,
            $injector: any,
            $log: ILogService,
            deviceService: IDeviceService,
            errorLoggingService: IErrorLoggingService
        ): IApiService => {
            return new apiService($http, $rootScope, $q, $injector, $log, deviceService, errorLoggingService);
        }
    ];
}

class apiService implements IApiService {
    constructor(
        private $http: angular.IHttpService,
        private $rootScope: any,
        private $q: angular.IQService,
        private $injector: any,
        private $log: ILogService,
        private deviceService: IDeviceService,
        private errorLoggingService: IErrorLoggingService
    ) {}

    activePromises = [];

    outagesBaseUrl = "/api/outages";
    consentRequestBaseUrl = "/api/consent";

    // get
    getStudentMedicalConditionTypesUrl = "/api/student/medicalConditionTypes";

    getIsMaintenanceUrl = "/";
    getMinimumApiVersionUrl = "/api/versions/minimum";
    getQLearnLinkUrl = "/api/qlearn/link";
    getTermsAndConditionsUrl = "/api/termsandconditions/mobile";

    getAuthenticationCheckUrl = "/api/authentication/authenticationCheck";
    getCrsfTokenUrl = "/api/token/csrf-token";
    getDownloadTokenUrl = "/api/session/downloadToken";
    getRegistrationUrl = "/api/registration";
    getDelegatedViewerInvitationUrl = "/api/registration/delegated-viewer-invitation";
    getUserUrl = "/api/user";
    getUserEmailUrl = "/api/user/email/";
    getAdminViewUserUrl = "/api/AdminView/User";

    // Post
    loginUrl = "/api/authentication/login";
    logoutUrl = "/api/authentication/logout";
    settingsPasswordUrl = "/api/user/settings/password";
    settingsEmailUrl = "/api/user/settings/email";
    settingsMobileUrl = "/api/user/settings/mobile";
    reportAbsenceUrl = "/api/attendance/reportAbsence";
    submitAbsenceReasonUrl = "/api/attendance/submitAbsenceReason";
    reportMultipleAbsencesUrl = "api/attendance/reportMultipleAbsences";
    forgotPasswordRequestUrl = "api/forgot-password/request";
    forgotPasswordSubmitUrl = "api/forgot-password/submit";

    adminViewLogoutUrl = "api/AdminView/Logout";

    recordAuditUrl = "/api/audit";
    recordErrorUrl = "/api/errors";
    recordMobileAppNeedsUpgradeUrl = "/api/versions/recordMobileAppNeedsUpgrade";
    acceptTermsAndConditionsUrl = "/api/termsAndConditions/accept";
    acceptPendingTermsAndConditionsUrl = "/api/termsAndConditions/acceptPending";

    accountOwnerLoginUrl = "/api/authentication/account-owner/login";
    accountOwnerRegisterUrl = "/api/registration/account-owner-register";
    accountOwnerActivateUrl = "/api/registration/account-owner-auto-activate";
    accountOwnerInvitationUrl = "/api/registration/account-owner-invitation";

    delegatedViewerLoginUrl = "/api/authentication/delegated-viewer/login";
    delegatedViewerRegisterUrl = "/api/registration/delegated-viewer-register";
    delegatedViewerInviteUrl = "/api/viewer/invite";
    delegatedViewerUpdateUrl = "/api/viewer/update";
    delegatedViewerResendInviteUrl = "/api/viewer/resendinvite";
    delegatedViewerChangeStatusUrl = "/api/viewer/changeStatus";
    associateAdditionalInvitationUrl = "/api/registration/associate-additional-invitation";

    saveUserUrl = "/api/user";
    saveStudentUrl = "/api/student";
    saveStudentRelationUrl = "/api/studentRelation";
    saveStudentMedicalConditionsUrl = "/api/student/medicalConditions";
    removeStudentPhotoUrl = "/api/studentphoto/remove";
    studentUseSchoolPhotoUrl = "/api/studentphoto/school";
    promoteStudentPhotoUrl = "/api/studentphoto/promote";
    uploadStudentPhotoUrl = "/api/studentphoto/upload/";
    emailStudentAcademicReportArchiveUrl = "/api/academic/reportArchive";

    paymentAuthenticationTokenUrl = "/api/processpayment/tokens";
    verifyPaymentResponseUrl = "/api/processpayment/verifyresponse";
    auditPaymentUrl = "/api/processpayment/auditpayment";

    registerPushNotificationUrl = "/api/pushnotification/register";
    unregisterPushNotificationUrl = "/api/pushnotification/unregister";

    public getIsMaintenance<T = any>() {
        return this.httpGet<T>(this.getIsMaintenanceUrl);
    }

    public getAuthenticationCheck<T>() {
        return this.httpGet<T>(this.getAuthenticationCheckUrl);
    }

    public getCrsfToken<T>() {
        return this.httpGet<T>(this.getCrsfTokenUrl);
    }

    public getOutageById<T>(id: string) {
        const url = `${this.outagesBaseUrl}/${id}`;
        return this.httpGet<T>(url);
    }

    public getOutage<T>(platform: string, module: string) {
        const url = `${this.outagesBaseUrl}/${platform}/outage/${module}`;
        return this.httpGet<T>(url);
    }

    public getAlert<T>(platform: string, module: string) {
        const url = `${this.outagesBaseUrl}/${platform}/alert/${module}`;
        return this.httpGet<T>(url);
    }

    public getMinimumApiVersion<T = any>() {
        return this.httpGet<T>(this.getMinimumApiVersionUrl);
    }

    public getDownloadToken<T>() {
        return this.httpGet<T>(this.getDownloadTokenUrl);
    }

    public getRegistration<T>(sessionId: string) {
        const url = `${this.getRegistrationUrl}?sessionId=${sessionId}`;
        return this.httpGet<T>(url);
    }

    public getDelegatedViewerInvitation<T>(code: string) {
        const url = `${this.getDelegatedViewerInvitationUrl}?code=${code}`;
        return this.httpGet<T>(url);
    }

    public getUser<T>() {
        const url = `${this.getUserUrl}?includeFutureStudents=true`;
        return this.httpGet<T>(url);
    }

    public getAdminViewUser<T>(schoolUserId: string, ppaoId: string, centreCode: string) {
        const url = `${this.getAdminViewUserUrl}?schoolUserId=${schoolUserId}&ppaoId=${ppaoId}&centreCode=${centreCode}`;
        return this.httpGet<T>(url);
    }

    public getUserEmail<T>(qid: string) {
        const url = `${this.getUserEmailUrl}/${qid}`;
        return this.httpGet<T>(url);
    }

    public getStudentMedicalConditionTypes<T>() {
        return this.httpGet<T>(this.getStudentMedicalConditionTypesUrl);
    }

    public getQLearnLink<T>() {
        return this.httpGet<T>(this.getQLearnLinkUrl);
    }

    public getTermsAndConditions<T>(toc: string) {
        const url = `${this.getTermsAndConditionsUrl}/${toc}`;
        return this.httpGet<T>(url);
    }

    public getConsentRequest<T>(studentEqId: string, requestId: string, schoolCode: string) {
        const url = `${this.consentRequestBaseUrl}/${studentEqId}/${requestId}/${schoolCode}`;
        return this.httpGet<T>(url);
    }

    public getConsentRequestSummary<T>(query: string) {
        const url = `${this.consentRequestBaseUrl}/summary?${query}`;
        return this.httpGet<T>(url);
    }

    public downloadAttachment<T>(url: string) {
        return this.httpGet<T>(url, { responseType: "arraybuffer" });
    }

    public downloadPhoto<T>(url: string) {
        return this.httpGet<T>(url, { responseType: "blob" });
    }

    public login<T>(data: LoginRequest) {
        return this.httpPost<T>(this.loginUrl, data);
    }

    public logout<T>() {
        return this.awaitPromisesThenPost<T>(this.logoutUrl);
    }

    public changePassword<T>(data: any) {
        return this.httpPost<T>(this.settingsPasswordUrl, data);
    }

    public changeEmail<T>(data: any) {
        return this.httpPost<T>(this.settingsEmailUrl, data);
    }

    public changeMobile<T>(data: any) {
        return this.httpPost<T>(this.settingsMobileUrl, data);
    }

    public reportAbsence<T>(data: any) {
        return this.httpPost<T>(this.reportAbsenceUrl, data);
    }

    public submitAbsenceReason<T>(data: any) {
        return this.httpPost<T>(this.submitAbsenceReasonUrl, data);
    }

    public reportMultipleAbsences<T>(data: any) {
        return this.httpPost<T>(this.reportMultipleAbsencesUrl, data);
    }

    public forgotPasswordRequest<T>(data: any) {
        return this.httpPost<T>(this.forgotPasswordRequestUrl, data);
    }

    public forgotPasswordSubmit<T>(data: any) {
        return this.httpPost<T>(this.forgotPasswordSubmitUrl, data);
    }

    public adminViewLogout<T>() {
        return this.awaitPromisesThenPost<T>(this.adminViewLogoutUrl);
    }

    public recordAudit<T>(data: any) {
        return this.httpPost<T>(this.recordAuditUrl, data);
    }

    public recordError<T>(data: any) {
        return this.httpPost<T>(this.recordErrorUrl, data, {});
    }

    public recordMobileAppNeedsUpgrade<T>(data: any) {
        return this.httpPost<T>(this.recordMobileAppNeedsUpgradeUrl, data);
    }

    public acceptTermsAndConditions<T>(data: any) {
        return this.httpPost<T>(this.acceptTermsAndConditionsUrl, data);
    }

    public acceptPendingTermsAndConditions<T>() {
        return this.httpPost<T>(this.acceptPendingTermsAndConditionsUrl);
    }

    public accountOwnerLogin<T>(data: any) {
        return this.httpPost<T>(this.accountOwnerLoginUrl, data);
    }

    public accountOwnerRegister<T>(data: any) {
        return this.httpPost<T>(this.accountOwnerRegisterUrl, data);
    }

    public accountOwnerActivate<T>(data: any) {
        return this.httpPost<T>(this.accountOwnerActivateUrl, data);
    }

    public accountOwnerInvitation<T>(data: any) {
        return this.httpPost<T>(this.accountOwnerInvitationUrl, data);
    }

    public delegatedViewerLogin<T>(data: any) {
        return this.httpPost<T>(this.delegatedViewerLoginUrl, data);
    }

    public delegatedViewerRegister<T>(data: any) {
        return this.httpPost<T>(this.delegatedViewerRegisterUrl, data);
    }

    public delegatedViewerInvite<T>(data: any) {
        return this.httpPost<T>(this.delegatedViewerInviteUrl, data);
    }

    public delegatedViewerUpdate<T>(data: any) {
        return this.httpPost<T>(this.delegatedViewerUpdateUrl, data);
    }

    public delegatedViewerResendInvite<T>(data: any) {
        return this.httpPost<T>(this.delegatedViewerResendInviteUrl, data);
    }

    public delegatedViewerChangeStatus<T>(data: any) {
        return this.httpPost<T>(this.delegatedViewerChangeStatusUrl, data);
    }

    public associateAdditionalInvitation<T>(data: any) {
        return this.httpPost<T>(this.associateAdditionalInvitationUrl, data);
    }

    public saveUser<T>(data: any) {
        return this.httpPost<T>(this.saveUserUrl, data);
    }

    public saveStudentRelation<T>(data: any) {
        return this.httpPost<T>(this.saveStudentRelationUrl, data);
    }

    public saveStudent<T>(data: any) {
        return this.httpPost<T>(this.saveStudentUrl, data);
    }

    public saveStudentMedicalConditions<T>(data: any) {
        return this.httpPost<T>(this.saveStudentMedicalConditionsUrl, data);
    }

    public removeStudentPhoto<T>(oneSchoolId: string) {
        const url = `${this.removeStudentPhotoUrl}/${oneSchoolId}`;
        return this.httpPost<T>(url);
    }

    public studentUseSchoolPhoto<T>(studentId: string) {
        const url = `${this.studentUseSchoolPhotoUrl}/${studentId}`;
        return this.httpPost<T>(url);
    }

    public promoteStudentPhoto<T>(studentId: string, type: string) {
        const url = `${this.promoteStudentPhotoUrl}/${studentId}?type=${type}`;
        return this.httpPost<T>(url);
    }

    public uploadStudentPhoto<T>(studentId: string, data: any) {
        const url = `${this.uploadStudentPhotoUrl}/${studentId}`;
        return this.httpPost<T>(url, data, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        });
    }

    public sendConsentResponse<T>(studentEqId: string, requestId: string, schoolCode: string, data: any) {
        const url = `${this.consentRequestBaseUrl}/${studentEqId}/${requestId}/${schoolCode}`;
        return this.httpPost<T>(url, data);
    }

    public emailAcademicReportArchive<T>(data: any) {
        return this.httpPost<T>(this.emailStudentAcademicReportArchiveUrl, data);
    }

    public paymentAuthenticationToken<T>(data: any) {
        return this.httpPost<T>(this.paymentAuthenticationTokenUrl, data);
    }

    public verifyPaymentResponse<T>(data: any) {
        return this.httpPost<T>(this.verifyPaymentResponseUrl, data);
    }

    public auditPayment<T>(data: any) {
        return this.httpPost<T>(this.auditPaymentUrl, data);
    }

    public registerPushNotification<T>(handle: string) {
        return this.deviceService.getDeviceId().then(deviceId => {
            return this.getInstallationId(deviceId).then(installationId => {
                return this.httpPost<T>(this.registerPushNotificationUrl, { handle: handle, installationId: installationId, platform: this.getPushNotificationPlatform() });
            });
        });
    }

    public unregisterPushNotification<T>(handle: string) {
        return this.deviceService.getDeviceId().then(deviceId => {
            return this.getInstallationId(deviceId).then(installationId => {
                return this.httpPost<T>(this.unregisterPushNotificationUrl, { handle: handle, installationId: installationId });
            });
        });
    }

    public httpGet<T>(url: string, config?: angular.IRequestShortcutConfig, isUseCapacitorHttp: boolean = this.deviceService.isMobileApp) {
        // Unable to set the cookies in ios
        // See: https://forum.ionicframework.com/t/cookie-based-authentication-for-ios-for-ionic-react-capacitor/197277/6

        const deferred = this.$q.defer<HttpResponse | IHttpResponse<T>>();
        let httpGet: Promise<any> | angular.IHttpPromise<T>;

        if (isUseCapacitorHttp) {
            this.$log.debug(`apiServiceProvider - Capacitor HTTP Get Request for ${url}`);
            httpGet = this.capacitorHttpGet(url, config);
        } else {
            this.$log.debug(`apiServiceProvider - HTTP Get Request for ${url}`);
            httpGet = this.$http.get<T>(url, config);
        }

        httpGet
            .then(response => {
                this.$log.debug(`apiServiceProvider - HTTP GET Request Succeed.`);
                deferred.resolve(response);
            })
            .catch(error => {
                this.$log.error(`apiServiceProvider - HTTP GET Request failed.`, angular.toJson(error));
                this.errorLoggingService.responseError(error);
                deferred.reject(error);
            })
            .finally(() => {
                const index = this.activePromises.indexOf(deferred);
                if (index !== -1) {
                    this.activePromises.splice(index, 1);
                }
            });

        // Keep track of the promise
        this.activePromises.push(deferred);

        return deferred.promise;
    }

    private capacitorHttpGet(url: string, config?: angular.IRequestShortcutConfig): Promise<HttpResponse> {
        const urlResolver: IUrlResolver = this.$injector.get("urlResolver");

        return getHttpHeaders(this.$rootScope.userSessionId, this.deviceService).then(headers => {
            const httpOptions: HttpOptions = this.getCapacitorHttpOptions(urlResolver, url, headers, config);

            return CapacitorHttp.get(httpOptions);
        });
    }

    httpPost<T>(url: string, data?: any, config?: angular.IRequestShortcutConfig, isUseCapacitorHttp: boolean = this.deviceService.isMobileApp) {
        const deferred = this.$q.defer<HttpResponse | IHttpResponse<T>>();
        let httpPost: Promise<any> | angular.IHttpPromise<T>;

        if (isUseCapacitorHttp) {
            this.$log.debug(`apiServiceProvider - Capacitor HTTP POST Request for ${url}`);
            httpPost = this.capacitorHttpPost(url, config, data);
        } else {
            this.$log.debug(`apiServiceProvider - HTTP POST Request for ${url}`);
            httpPost = this.$http.post<T>(url, data, config);
        }

        httpPost
            .then(response => {
                this.$log.debug(`apiServiceProvider - HTTP POST Request Succeed.`);
                deferred.resolve(response);
            })
            .catch(error => {
                this.$log.error(`apiServiceProvider - HTTP POST Request failed.`, angular.toJson(error));
                this.errorLoggingService.responseError(error);
                deferred.reject(error);
            })
            .finally(() => {
                const index = this.activePromises.indexOf(deferred);
                if (index !== -1) {
                    this.activePromises.splice(index, 1);
                }
            });

        // Keep track of the promise
        this.activePromises.push(deferred);

        return deferred.promise;
    }

    private capacitorHttpPost(url: string, config: angular.IRequestShortcutConfig, data: any) {
        const urlResolver: IUrlResolver = this.$injector.get("urlResolver");

        return getHttpHeaders(this.$rootScope.userSessionId, this.deviceService).then(headers => {
            const httpOptions: HttpOptions = this.getCapacitorHttpOptions(urlResolver, url, headers, config);
            if (httpOptions.headers["Content-Type"] == "application/json") {
                httpOptions.data = angular.toJson(data);
            } else {
                httpOptions.data = data;
            }
            return CapacitorHttp.post(httpOptions);
        });
    }

    private getCapacitorHttpOptions(urlResolver: IUrlResolver, url: string, headers: any[][], config?: angular.IRequestShortcutConfig) {
        const httpOptions: HttpOptions = {
            url: urlResolver.resolve(url),
            headers: {},
            responseType: config?.responseType as HttpResponseType,
            webFetchExtra: {
                credentials: "include"
            }
        };
        for (const [key, value] of headers) {
            httpOptions.headers[key] = value;
        }
        for (const requestType in config?.headers) {
            httpOptions.headers[requestType] = config?.headers[requestType];
        }

        return httpOptions;
    }

    private awaitPromisesThenPost<T>(url: string, data?: any) {
        // Await for active promises to be completed
        const deferred = this.$q.defer<HttpResponse | angular.IHttpResponse<T>>();
        const checkPromises = () => {
            if (this.activePromises.length === 0) {
                this.httpPost<T>(url, data)
                    .then(response => {
                        deferred.resolve(response);
                    })
                    .catch(error => {
                        deferred.reject(error);
                    });
            } else {
                this.$log.debug(`apiServiceProvider - There is ${this.activePromises.length} active request/s.`);
                setTimeout(checkPromises, 50);
            }
        };

        checkPromises();

        return deferred.promise;
    }

    private getPushNotificationPlatform() {
        return this.deviceService.isIOs() ? "apns" : "gcm";
    }

    private getInstallationId(deviceId: string) {
        const deferred = this.$q.defer<string>();
        let installationId = deviceId;

        this.deviceService.getAppInfo().then(appInfo => {
            // Generate the Hash of the Hexadecimal string\
            const hash = CryptoJs.SHA256(`${installationId}|${appInfo.id}`).toString(CryptoJs.enc.Hex);
            const namespace = "00000000-0000-0000-0000-000000000000";
            const newInstallationId = uuidv5(hash, namespace);
            deferred.resolve(newInstallationId);
            this.$log.debug(`apiServiceProvider - Installation Id ${newInstallationId}`);
        });

        return deferred.promise;
    }
}

app.provider(serviceId, appServiceProvider);
